import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`You can install the Python SDK using PIP with the commands listed below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ pip install paymentsds-mpesa
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-txt"
      }}>{`paymentsds-mpesa
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ pip install -r requirements.txt
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      